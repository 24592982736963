import { ClassUtils } from "../utils/ClassUtils";
import { BoxCollider } from "./Collider2D/BoxCollider";
import { ChainCollider } from "./Collider2D/ChainCollider";
import { CircleCollider } from "./Collider2D/CircleCollider";
import { ColliderBase } from "./Collider2D/ColliderBase";
import { EdgeCollider } from "./Collider2D/EdgeCollider";
import { DistanceJoint } from "./joint/DistanceJoint";
import { GearJoint } from "./joint/GearJoint";
import { JointBase } from "./joint/JointBase";
import { MotorJoint } from "./joint/MotorJoint";
import { MouseJoint } from "./joint/MouseJoint";
import { PrismaticJoint } from "./joint/PrismaticJoint";
import { PulleyJoint } from "./joint/PulleyJoint";
import { RevoluteJoint } from "./joint/RevoluteJoint";
import { WeldJoint } from "./joint/WeldJoint";
import { WheelJoint } from "./joint/WheelJoint";
import { Physics2D } from "./Physics2D";
import { Physics2DDebugDraw } from "./Physics2DDebugDraw";
import { PolygonCollider } from "./Collider2D/PolygonCollider";
import { RigidBody } from "./RigidBody";

let c = ClassUtils.regClass;
c("Physics2D", Physics2D);
c("Physics2DDebugDraw", Physics2DDebugDraw);
c("ColliderBase", ColliderBase);
c("BoxCollider", BoxCollider);
c("ChainCollider", ChainCollider);
c("CircleCollider", CircleCollider);
c("EdgeCollider", EdgeCollider);
c("PolygonCollider", PolygonCollider);
c("RigidBody", RigidBody);
c("JointBase", JointBase);
c("DistanceJoint", DistanceJoint);
c("GearJoint", GearJoint);
c("MotorJoint", MotorJoint);
c("MouseJoint", MouseJoint);
c("PrismaticJoint", PrismaticJoint);
c("PulleyJoint", PulleyJoint);
c("RevoluteJoint", RevoluteJoint);
c("WeldJoint", WeldJoint);
c("WheelJoint", WheelJoint);